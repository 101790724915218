import dynamic from 'next/dynamic';
import React, { FC, useMemo } from 'react';

import { useIntl } from 'react-intl';
import {
  useViewDevice,
  ViewDevice,
} from '../../../../contexts/ViewDeviceContext';
import { useShallowEqualSelector } from '../../../../hooks/redux';
import { MerchantDetail } from '../../../../interfaces/merchants';
import { ReduxState } from '../../../../redux/reducers';
import Heading from '../../../../atoms/Heading';
import BaseTemplate from '../../../../organisms/merchants/BaseTemplate';
import MerchantProfile from '../../../../molecules/merchant/MerchantProfile';

import css from './MerchantCalendarPage.module.scss';
import { ComponentProps as FullCalendarProps } from '../../../../molecules/merchant/calendar/pc/MerchantFullCalendar';
import { SpAllBookingPagesCalendar } from '../../../../organisms/merchants/services/SpAllBookingPagesCalendar';
import Head from 'next/head';
import {
  today,
  DEFAULT_TZ,
} from '../../../../molecules/merchant/calendar/calendarHelper';
import { RSButtonAnchor } from 'src/reservation-page/RSButtonAnchor';

export interface PageProps {
  merchantDetail: MerchantDetail;
  isCoubicApp: boolean;
}

const MerchantFullCalendar = dynamic<FullCalendarProps>(
  import(
    '../../../../molecules/merchant/calendar/pc/MerchantFullCalendar'
  ).then((c) => c.default),
  {
    loading: () => <span />,
    ssr: false,
  },
);

const MerchantCalendarPage: FC<PageProps> = ({
  merchantDetail,
  isCoubicApp,
}) => {
  const intl = useIntl();
  const reduxState = useShallowEqualSelector(
    (state: ReduxState) => state.merchantsServices,
  );
  const viewDeviceCtx = useViewDevice();

  const { services, baseDate, fetchedUntil, viewStyle, meta } = reduxState;

  // カレンダーの初期表示範囲の日付を求める
  const startDate = today(DEFAULT_TZ).startOf('day');

  const pcEndDate = useMemo(() => {
    const lastDayWeekDay = startDate.endOf('month');
    const lastDayWeekDayNumber = lastDayWeekDay.weekday;
    const offsetDay = lastDayWeekDayNumber === 7 ? 6 : 6 - lastDayWeekDayNumber;
    return lastDayWeekDay.plus({ days: offsetDay });
  }, [startDate]);

  const spEndDate = startDate.plus({ days: 3 }).endOf('day');

  return (
    <>
      <Head>
        {/* カレンダー関連APIのプリロード */}
        <link
          rel="preload"
          as="fetch"
          href={`/api/v2/merchants/${
            merchantDetail.public_id
          }/booking_events?start=${startDate.toISO()}&end=${spEndDate.toISO()}`}
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="fetch"
          href={`/api/v2/merchants/${
            merchantDetail.public_id
          }/booking_events?renderer=fullcalendar&start=${startDate.toISO()}&end=${pcEndDate.toISO()}`}
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="fetch"
          href={`/api/v2/merchants/${merchantDetail.public_id}/booking_calendar_meta`}
          crossOrigin="anonymous"
        />
      </Head>
      <div>
        <BaseTemplate merchantDetail={merchantDetail} isCoubicApp={isCoubicApp}>
          <div className={css.MerchantCalendarPage}>
            <div>
              {/* for webPc */}
              {viewDeviceCtx.currentViewDevice === ViewDevice.WebPc && (
                <>
                  <Heading className={css.MerchantCalendarPage__Heading}>
                    {intl.formatMessage({
                      id: 'merchants.services.index.title',
                    })}
                  </Heading>
                  <MerchantFullCalendar
                    merchantPublicId={merchantDetail.public_id}
                    merchantTimeZone={merchantDetail.time_zone}
                    baseDate={baseDate as string}
                  />
                </>
              )}

              {/* for webSp */}
              {viewDeviceCtx.currentViewDevice === ViewDevice.WebSp && (
                <SpAllBookingPagesCalendar
                  baseDate={baseDate}
                  meta={meta}
                  services={services}
                  fetchedUntil={fetchedUntil}
                  merchantPublicId={merchantDetail.public_id}
                  merchantTimeZone={merchantDetail.time_zone}
                  viewStyle={viewStyle}
                />
              )}
            </div>
            <div className={css.MerchantCalendarPage__MerchantProfileSection}>
              <Heading className={css.MerchantCalendarPage__Heading}>
                {intl.formatMessage(
                  { id: 'merchants.details.storeData' },
                  { displayName: merchantDetail.display_name },
                )}
              </Heading>
              <MerchantProfile merchantDetail={merchantDetail} />
              <div className={css.MerchantCalendarPage__BackToTopButtonSection}>
                <div className="mx-auto max-w-[370px]">
                  <RSButtonAnchor
                    variant="outline"
                    isBlock
                    href={`/${merchantDetail.public_id}`}
                  >
                    {intl.formatMessage(
                      {
                        id: 'merchants.details.backToMerchantTop',
                      },
                      { displayName: merchantDetail.display_name },
                    )}
                  </RSButtonAnchor>
                </div>
              </div>
            </div>
          </div>
        </BaseTemplate>
      </div>
    </>
  );
};

export default MerchantCalendarPage;
